//@prepros-prepend vendors/animsition/animsition.min.js
//@prepros-prepend vendors/greedy-nav/greedynav.js
//@prepros-prepend vendors/isotope/isotope.pkgd.min.js
//@prepros-prepend vendors/images-loaded/imagesloaded.pkgd.js
//@prepros-prepend vendors/fancybox/jquery.fancybox.pack.js
//@prepros-prepend vendors/fancybox-transitions/jquery.fancybox.transitions.js
//@prepros-prepend vendors/scroll-magic/ScrollMagic.min.js
//@prepros-prepend vendors/scroll-magic/plugins/debug.addIndicators.min.js
//@prepros-prepend vendors/tooltipster/jquery.tooltipster.min.js
//@prepros-prepend vendors/slick/slick.min.js
//@prepros-prepend vendors/match-height/jquery.matchHeight-min.js
//@prepros-prepend vendors/fancy-select/fancySelect.js
//@prepros-prepend vendors/reading-time/readingTime.js
//@prepros-prepend vendors/dotdotdot/jquery.dotdotdot.js


$(document).ready(function() {

	// Amends Zendesk/Zopim API to have residor specific colours and messages
	$zopim( function() {
		$zopim.livechat.badge.setColor('#008081');

		$zopim.livechat.bubble.setColor('#008081');
		$zopim.livechat.bubble.setText('Leave a question or comment and one of our Residor experts will attend to you shortly.');

	    $zopim.livechat.prechatForm.setGreetings('Please introduce yourself and one of our Residor experts will attend to you shortly.');

		$zopim.livechat.theme.setColor('#008081');

		$zopim.livechat.theme.reload();
	});

	/* Viewport */

	var vw = $(window).width();

	/* Animsition */

	$(".js-animsition").animsition({
		inClass: 'fade-in',
		outClass: 'fade-out',
		inDuration: 1500,
		outDuration: 800,
		linkElement: 'a[href]:not([target="_blank"]):not([href^=#]):not([href^="tel"]):not(".kill-fade"):not(".fancybox, .fancybox-nav, .fancybox-close, .js-callback, .js-installer-gallery")',
		loading: true,
		loadingParentElement: 'body', //animsition wrapper element
		loadingClass: 'animsition-loading',
		loadingInner: '', // e.g '<img src="loading.svg" />'
		timeout: false,
		timeoutCountdown: 5000,
		onLoadEvent: true,
		browser: [ 'animation-duration', '-webkit-animation-duration'],
		// "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
		// The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
		overlay : false,
		overlayClass : 'animsition-overlay-slide',
		overlayParentElement : 'body',
		transition: function(url){ window.location.href = url; }
	});

	/* Parallax */

	function parallax() {

		var $window = $(window);

		$('[data-parallax]').each(function (e) {

			var $this = $(this);
			var yPos;
			var speed = ($this.data('speed') || 1.5 );

			$window.on('scroll resize', function (){
				yPos = 50 - $window.scrollTop() * 100 / $window.height() / speed;
				$this.css('background-position', '50% ' + yPos + '%');
			});

		});

	  $window.trigger('scroll');

	};

	parallax();

	/* Smooth scroll */

	var headerHeight = $('header').outerHeight() - 1;
	var triggerOffset = ($(window).height() / 100 * 9) - 1;

	$('a[href*=#]:not([href=#]):not(".js-popupform")').click(function(e) {

		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {

				if ( target.hasClass('arrow-bottom') ) {

					if ( $(this).hasClass('js-section-scroll') ) {
						console.log('section scroll!');

						$('html,body').animate({
							scrollTop: target.offset().top - triggerOffset
						}, 750);
						return false;

					} else {
						console.log('arrow bottom!');

						$('html,body').animate({
							scrollTop: target.offset().top - (headerHeight - 32)
						}, 750);
						return false;

					}

				} else if ( target.hasClass('accordion') ) {
					console.log( $(this).attr('href') );

					e.preventDefault();

				} else {
					console.log('no arrow');

					$('html,body').animate({
						scrollTop: target.offset().top - headerHeight
					}, 750);
					return false;

				}

			}
		}

	});

	$('.js-scroll-more').click(function(e) {

		e.preventDefault();

		var target = $(this).closest('.relative').next('div');

		$('html,body').animate({
			scrollTop: target.offset().top - headerHeight
		}, 750);
		return false;

	});

	/* Scroll Magic */

	// init controller
	var controller = new ScrollMagic.Controller({ globalSceneOptions: {triggerHook: .09}}); // globalSceneOptions: {duration: 100}

	function sectionScroll() {

		// build scenes
		var sectionOneHeight = $('#section-1').outerHeight();
		var sectionTwoHeight = $('#section-2').outerHeight();
		var sectionThreeHeight = $('#section-3').outerHeight();
		var sectionFourHeight = $('#section-4').outerHeight();
		var sectionFiveHeight = $('#section-5').outerHeight();
		var sectionSixHeight = $('#section-6').outerHeight();
		var sectionSevenHeight = $('#section-7').outerHeight();
		var sectionEightHeight = $('#section-8').outerHeight();

		new ScrollMagic.Scene({triggerElement: "#section-1", duration: sectionOneHeight})
			.setClassToggle("#anchor-1", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-2", duration: sectionTwoHeight})
			.setClassToggle("#anchor-2", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-3", duration: sectionThreeHeight})
			.setClassToggle("#anchor-3", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-4", duration: sectionFourHeight})
			.setClassToggle("#anchor-4", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-5", duration: sectionFourHeight})
			.setClassToggle("#anchor-5", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-6", duration: sectionFourHeight})
			.setClassToggle("#anchor-6", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-7", duration: sectionFourHeight})
			.setClassToggle("#anchor-7", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
		new ScrollMagic.Scene({triggerElement: "#section-8", duration: sectionFourHeight})
			.setClassToggle("#anchor-8", "active") // add class toggle
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);

	};

	sectionScroll();

	/* Accordions */

	$('.accordions > a').on('click', function() {

		// Hide others
		if ( $('.accordions .selected').length > 0 ) {
			$(this).closest('.accordions').find('.question').removeClass('selected');
		}

		// Open selected
		$(this).addClass('selected');

		/* Hide selected
		if ( $(this).hasClass('selected') ) {
			console.log('selected');
			$(this).removeClass('selected');
			$(this).closest('.accordions').find('.answer').removeClass('open');
		} else {
			console.log('not selected')
		}*/

		// Hide others
		if ( $('.accordions .open').length > 0 ) {
			$(this).closest('.accordions').find('.answer').removeClass('open');
		}

		// Open selected
		$(this).next('.answer').addClass('open');

		// Scroll to
		$('html,body').animate({
			scrollTop: $(this).offset().top - (headerHeight * 3)
		}, 250);
		return false;

	});

	/* SVG Fallback */

	if (!Modernizr.svg) {
		var imgs = document.getElementsByTagName('img');
		var svgExtension = /.*\.svg$/
		var l = imgs.length;
		for(var i = 0; i < l; i++) {
			if(imgs[i].src.match(svgExtension)) {
				imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
				console.log(imgs[i].src);
			}
		}
	}

	/* Match Height */

	$('.js-match-height').matchHeight();

	/* Match Parent */

	function matchParent() {

		/*var vwResize = $(window).width();
		var vhResize = $(window).height();*/

		var mp = $('.js-match-parent').closest('.js-match-height').outerHeight();

		$('.js-match-parent').height(mp);

	}

	//matchParent();

	/* Pods */

	function headingHeights() {

		var headingHeight = 0;

		$('.js-pods').find('h3').each(function() {

			$(this).height('');
			var h = $(this).height();
			headingHeight = Math.max(headingHeight, h);

		}).height(headingHeight);

	}

	//headingHeights();

	/* Latest News */

	function latestNews() {

		var headingHeight = 0;

		$('.latest-news-slider').find('h3').each(function() {

			$(this).height('');
			var h = $(this).height();
			headingHeight = Math.max(headingHeight, h);

		}).height(headingHeight);


		var pHeight = 0;

		$('.latest-news-slider').find('p').each(function() {

			$(this).height('');
			var p = $(this).height();
			pHeight = Math.max(pHeight, p);

		}).height(pHeight);

	}

	latestNews();

	/* Truncate */

	$('.js-truncate').dotdotdot({
        watch: 'window'
    });

	$('.cta-description').dotdotdot({
		watch: 'window',
        ellipsis: ' ➞'
    });

	/* Sliders */

	var $banners = $('.main-banner, .full-banner, .short-banner, .centred-banner');

	// On after slide change
	$banners.on('init', function(event, slick, currentSlide, nextSlide, prevSlide){
		$('.js-caption').hide();

		setTimeout(function() {
			$('.js-caption').fadeIn(1000);
			$('.slick-current').addClass('fade-in');
		}, 2500);

		//console.log('init');
	});

	// On before slide change
	$banners.on('beforeChange', function(event, slick, currentSlide, nextSlide, prevSlide){
		$('.js-caption').hide();
		$('.slick-current').removeClass('fade-in');
		//console.log('before');
	});

	// On after slide change
	$banners.on('afterChange', function(event, slick, currentSlide, nextSlide, prevSlide){

		setTimeout(function() {
			$('.js-caption').fadeIn(1000);
			$('.slick-current').addClass('fade-in');
		}, 2500);

		//console.log('after');
	});

	$banners.slick({
		arrows: false,
		dots: true,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 10000,
		pauseOnHover: false
	});

	$('.standard-banner').slick({
		arrows: false,
		dots: true,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 10000,
		pauseOnHover: false
	});

	$('.door-slider').slick({
		arrows: true,
		dots: false,
		speed: 200,
		centerMode: true,
		centerPadding: '100px',
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2500,
		speed: 500,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false
				}
			}
		]
	});

	/*$('.door-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide, prevSlide){
		$('.range-tooltip').tooltipster('hide');
		//console.log('before');
	});*/

	$('.gallery-slider').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: false,
		dots: true,
		draggable: false,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.gallery-fallback').slick({
		arrows: true,
		dots: false,
		draggable: false,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					arrows: false,
					dots: false,
					draggable: true
				}
			}
		]
	});

	$('.info-slider').slick({
		arrows: true,
		dots: false,
		draggable: false,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					arrows: false
				}
			},
			{
				breakpoint: 640,
				settings: {
					arrows: false,
					dots: true
				}
			}
		]
	});

	$('.inspiration-slider').slick({
		arrows: true,
		dots: false,
		draggable: false,
		adaptiveHeight: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					arrows: false,
					dots: true
				}
			},
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					arrows: false,
					dots: true
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: true
				}
			}
		]
	});

	$('.article-gallery').slick({
		arrows: true,
		dots: false,
		//draggable: false,
		asNavFor: '.article-gallery-nav'
	});

	$('.article-gallery-nav').slick({
		arrows: true,
		dots: false,
		draggable: false,
		asNavFor: '.article-gallery',
		slidesToShow: 5,
		centerMode: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 3,
					arrows: false
				}
			}
		]
	});

	/* Captions */

	function positionCaption() {

		var podsHeight = $('.js-pods').outerHeight();
		var captionOffset = podsHeight;

		if ( vw >= 960 ) {
			//console.log('640 or greater');

			if ( $('.main-banner').length > 0 || $('.main-banner .js-caption').length > 0 ) {

				$('.main-banner .js-caption').css({
					'padding-bottom': captionOffset
				});

				$('.main-banner .slick-dots').css({
					'bottom': podsHeight
				});

        $('.js-logos').css({
  				'bottom': podsHeight
  			});

				$('.js-scroll-wrapper').css({
					'bottom': podsHeight - 24
				});

			}

		} else {
			//console.log('less than 640');

			$('.main-banner .js-caption').css({
				'padding-bottom': 0
			});

			$('.main-banner .slick-dots').css({
				'bottom': 0
			});

      $('.js-logos').css({
				'bottom': podsHeight
			});

			$('.js-scroll-wrapper').css({
				'bottom': podsHeight
			});

		}

	}

	positionCaption();

	/* No slider */

	 if ( $('.main-banner, .full-banner, .short-banner, .centred-banner').length > 0 ) {
		//console.log('slider');
	 } else {
		//console.log('no slider');

		var headingHeight = $('header').height();

		$('header').next().css({
			'margin-top': headingHeight
		});

	 }

	/* Find local stockist */

	var $stockist = $('.js-find-stockist');
	var $searchStockist = $('.js-stockist-search');
	var $call = $('.js-call-today');

	var callWidth = 0;
	var callOffset = 0;
	var searchOffset = 0;

	$stockist.find('a').on('click', function(e) {
		e.preventDefault();

		if ( !$('.hidden-links').hasClass('hidden') ) {
			//console.log('menu open');
			$('.hidden-links').toggleClass('hidden');
		}

		$('html').toggleClass('stockist-open');


		stockistToggle();

		/* Focus input */

		if ( $('html').hasClass('stockist-open') ) {

			$searchStockist.find('input').focus();

		} else {

			$searchStockist.find('input').blur();

		}

	});

	function stockistToggle() {

		if ( vw > 959 ) {
			//console.log('md up');

			callWidth = $call.outerWidth();
			callOffset = callWidth * -1;
			searchOffset = callWidth * -1;

			if ( $('html').hasClass('stockist-open') ) {

				$stockist.css({
					'left': callOffset
				});

				$searchStockist.css({
					'right': 0,
					'left': 'auto',
					'top': 0,
					'width': callWidth
				});

			} else {

				$stockist.css({
					'left': 'auto'
				});

				$searchStockist.css({
					'right': searchOffset
				});

			}

		} else {
			//console.log('sm down');

			if ( $('html').hasClass('stockist-open') ) {

				$stockist.css({
					'left': 'auto'
				});

				$searchStockist.css({
					'right': 0,
					'left': 0,
					'top': 100 + '%',
					'width': 100 + '%'
				});

			} else {

				$stockist.css({
					'left': 'auto'
				});

				$searchStockist.css({
					'right': -100 + '%',
					'left': 'auto'
				});

			}

		}

	}

	/* Greedy nav */

	function greedyNav() {

		var greedyBtn = $('.greedy button')
		var greedyList = $('.greedy > ul');
		var greedyVisible = greedyList.children().length;

		//console.log(greedyVisible);

		if ( greedyVisible != 0 ) {
			greedyBtn.html('<span class="icon-caret-down brand"></span>More');
			$('.menu-trigger').css({
				width: 'auto',
			    height: 'auto'
			});
		} else {
			greedyBtn.html('<span class="hamburger icon-hamburger"></span>');
			$('.menu-trigger').css({
				width: '4.5rem',
			    height: '54px'
			});
		}

	}

	greedyNav();

	/* Hidden links */

	$('.greedy button').on('click', function() {

		if ( $('html').hasClass('stockist-open') ) {
			$('html').removeClass('stockist-open');
		}

		if ( vw < 959 ) {
			stockistToggle();
		}

		var vh = $(window).height();
		var hh = $('header').outerHeight();
		var hiddenLinks = $('.greedy .hidden-links');
		var hiddenLinksHeight = vh - hh;

		hiddenLinks.css({
			'max-height': hiddenLinksHeight
		});

		//console.log(hiddenLinksHeight);
	});

	/* Fancybox */

	var baContent = '<div class="absolute top-0 left-50 translate-x width-100"> \
						<div class="btn-group text-center"> \
							<label class="btn btn-small bg-grey darken-1 border-none rounded-left uppercase inline" for="before"> \
								<input type="radio" id="before" name="before-after" class="hide" value="before">Before \
							</label> \
							<label class="btn btn-small bg-grey darken-1 border-none rounded-right uppercase inline active" for="after"> \
								<input type="radio" id="after" name="before-after" class="hide" value="after" checked>After \
							</label> \
						</div> \
					</div>';

	$('.fancybox').fancybox({
		padding: 3,
		afterLoad: function () {
			$.extend(this, {
				tpl: {
					image: '<div class="before-after">' + baContent + '<img class="fancybox-image after" src="{href}" alt="" /><img class="fancybox-image before" src="' + $(this.element).data('before') + '" alt="" /></div>',
					next: '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span class="icon-angle-right"></span></a>',
					prev: '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span class="icon-angle-left"></span></a>',
					closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">&times;</a>'
				}
			});
		},
		beforeShow: function() {
			$('.before').hide();

			//console.log( $(this.element).data('before') );

			if ( $(this.element).data('before') == '' ) {
				//console.log('no before');

				$('.before-after .btn-group').hide();

			} else {
				//console.log('has before');

				$('.before-after .btn-group').delay(300).fadeIn(300);
			}

		}
	});

	$('body').on('change', 'input[name="before-after"]', function() {
		//console.log( $(this).val() );

		if ( $(this).val() === 'after' ) {
			$('.after').show();
			$('.before').hide();
		} else {
			$('.after').hide();
			$('.before').show();
		}
	});

	$('.js-installer-gallery').fancybox({
	   afterLoad: function () {
	      $.extend(this, {
	         tpl: {
	            image: '<img class="fancybox-image" src="{href}" alt="" />',
	            next: '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span class="icon-angle-right"></span></a>',
	            prev: '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span class="icon-angle-left"></span></a>',
	            closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">&times;</a>'
	         }
	      });
	   }
	});


	$('.js-callback').fancybox({
		type: 'ajax',
		afterLoad: function () {
			$.extend(this, {
				tpl: {
					closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">&times;</a>'
				}
			});
		},
		beforeLoad: function () {
			this.title = '<h3 class="mt0 mb1 fw400 sm-fw300">Contact <strong>' + this.title + '</strong></h3>';
		},
		helpers : {
			title: {
				type: 'inside',
				position: 'top'
			}
		}
	});

	$(document).on('click', '.js-callback', function(e) {
		var stockistID = $(this).data('stockist');
		console.log(stockistID);
		$('#stockist-id').val(stockistID);
	});

	var videoHeight = screen.height/1.5;

	$('.fancybox-video').fancybox({
		padding: '0',
		type: 'iframe',
		iframe: {
			preload: true
		},
		width: 16/9 * videoHeight,
		height: videoHeight,
		autoDimensions: false,
		aspectRatio: true,
		afterLoad: function () {
			$.extend(this, {
				tpl: {
					closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">&times;</a>'
				}
			});
		},
		helpers: {
			media: {}
		}
	});


	$(".js-popupform").fancybox({
		'transitionIn'		: 'none',
		'transitionOut'	: 'none',
		'maxWidth'			: 560,
		openMethod			: 'dropIn',
		closeMethod 		: 'dropOut',
		afterLoad: function () {
			$.extend(this, {
				tpl: {
					closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">&times;</a>'
				}
			});
		},
	});


	/* Dropdown */

	//$('[data-toggle="dropdown"]').hide();

	$('[data-toggle="trigger"]').on('click', function(e) {
		e.preventDefault();

		$(this).closest('[data-toggle="container"]').toggleClass('open');

		//$(this).closest('[data-toggle]').find('[data-toggle="dropdown"]').toggle();

	});

	/* Fancy Select */

	var $selectPicker = $('.selectpicker, .gfield_select');
	$selectPicker.fancySelect();

	$selectPicker.fancySelect().on('change.fs', function() {
		$(this).trigger('change.$');
	});

	/* Select URL */

	var currentPath = window.location.pathname;

	$('.js-select-url').on('change', function () {

		var url = $(this).val();

		if (url) {
			if (url != currentPath) {
				window.location = url;
			}
		}

		return false;

	});

	/* Nav scroll */

	function navScroll() {

		//console.log('scroll');

		var viewportWidth = $(window).width();

		if (viewportWidth < 1200) {
			//console.log('less than 1200');

			$('html').removeClass('nav-scroll');

		} else {
			//console.log('more than 1200');

			if ( $('header').offset().top > 200 - $(window).scrollTop() ) {

				$('html').removeClass('nav-scroll');

			} else {
				//console.log('less than 200');

				$('html').addClass('nav-scroll');

			}

		}

	}

	navScroll();

	/* Button group */

	$('body').on('change', '.btn-group input', function() {

		//console.log('change!');

		if ( $(this).is(':checked') ) {

			//console.log('checked');

			$(this).closest('.btn-group').find('label').removeClass('active');

			$(this).closest('label').toggleClass('active');

		} else {
			//console.log('not checked');

			$(this).closest('label').removeClass('active');

		}

	});

	/* Breadcrumbs */

	function breadcrumbOffset() {

		var $breadcrumbs = $('.js-breadcrumbs');

		if ( $breadcrumbs.length > 0 ) {

			if ( vw >= 1200 ) {
				$breadcrumbs.css('left', 154);
			} else {
				$breadcrumbs.css('left', 0);
			}

		}

	}

	breadcrumbOffset();

	/* Reading time */

	var $article = $('article');

	$article.readingTime({
		readingTimeTarget: $('.reading-time .eta'),
		//wordCountTarget: $article.find('.word-count'),
		wordsPerMinute: 275,
		//round: false,
		lang: 'en',
		success: function() {
		},
		error: function(message) {
			$article.find('.reading-time .eta').remove();
		}
	});


	$(window).on('resize', function() {

		//console.log('resize!');

		vw = $(window).width();

		//matchParent();

		sectionScroll();
		navScroll();
		//headingHeights();
		positionCaption();
		greedyNav();
		//listSelect();
		breadcrumbOffset();

	});

	$(window).on('scroll', function() {

		navScroll();

	});

	$('#acc-filters .btn').click(function() {
		$('#accessories-description').html($(this).children('.acc-cat-desc').html());
	});

	$("<select />").appendTo("#colour-select");
	$("<option />", {
		 "selected": "selected",
		 "value"   : "",
		 "text"    : "Select Colour..."
	}).appendTo("#colour-select select");

	$("#colour-select a").each(function() {
	 var el = $(this);
	 $("<option />", {
			 "value"   : el.attr("href"),
			 "text"    : el.text()
	 }).appendTo("#colour-select select");
	});

	var $colourSelect = $('#colour-select select');
	$colourSelect.fancySelect();

	$colourSelect.fancySelect().on('change.fs', function() {
	    $(this).trigger('change.$');
	});

	$("#colour-select select").change(function() {
		window.location = $(this).find("option:selected").val();
		console.log('change');
	});


	$(function() {
		$('#find-form :submit').click(function(e) {
			$('#find-postcode').each(function() {
				if ($(this).val().length == 0) {
					$(this).css('border', '2px solid red');
					e.preventDefault();
				}
			});
		});
	});

	$(function() {
		$('#form-search_installer :submit').click(function(e) {
			$('#search_installer').each(function() {
				if ($(this).val().length == 0) {
					$('.icon-search').css('color', 'red');
					e.preventDefault();
				}
			});
		});
	});
});


$(window).on('load', function() {

	/* Isotope */

	var $container = $('.filter-container');

	$container.isotope({
		itemSelector: '.item',
		//filter: '',
		percentPosition: true,
		masonry: {
			columnWidth: '.grid-sizer'
		}
	});

	var $output = $('#output');

	function doorFilter(colour, rangeid) {
		$.ajax({
			method: "GET",
			url: "/residor/ajax/colourswitch",
			data: {
				colour: colour,
				rangeid : rangeid
			}
		}).done(function(msg) {
			$('#slick-replace').html(msg);

			$('.door-slider').slick({
				arrows: true,
				dots: false,
				draggable: false,
				speed: 200,
				centerMode: true,
				infinite: true,
				slidesToShow: 5,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							draggable: true,
							arrows: false
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false
						}
					}
				]
			});

			var $rangeTooltip = $('.range-tooltip');
			var rangeTooltipWidth = $rangeTooltip.outerWidth() + 32;
			var rangeOffset = $rangeTooltip.outerHeight() / 2.5;

			$rangeTooltip.tooltipster({
				animation: 'fade',
				contentAsHTML: true,
				//autoClose: false,
				delay: 500,
				hideOnClick: true,
				touchDevices: true,
				maxWidth: rangeTooltipWidth,
				offsetY: '-' + rangeOffset
			});

		});
	};

	$('#door-detail-colour-filter .btn:reset').click(function() {
		if($('.filters .colours input[type="radio"]').is(':checked')) {
			var colour = 'all';
			var rangeid = $('.filter-set-1').attr('id');

			doorFilter(colour, rangeid);
		}
	});

	$('#door-detail-colour-filter select, #door-detail-colour-filter input').change(function() {
		var colour = $(this).attr('id');
		var rangeid = $('.filter-set-1').attr('id');

		doorFilter(colour, rangeid);
	});

	$('.filter-set-1 select, .filter-set-1 input').change( function() {
		var $output = $('#output');
		$output.html("");

		$('.no-matches').fadeOut(100);

		var selectors = "";
		var selectors1 = [];
		var selectors2 = [];

		$(".filter-set-1").find("input:checked, option:selected").each(function() {
			selectors1.push('.' + $(this).data('filter'));
		});

		if (selectors1.length == 0)
			selectors1.push("");
		if (selectors2.length == 0)
			selectors2.push("");

		var filterValue = '';

		for (var i = 0; i < selectors1.length; i++) {
			for (var j = 0; j < selectors2.length; j++) {

			  filterValue += selectors1[i] + selectors2[j] + ",";

			};
		};

		filterValue = filterValue.substring(0, filterValue.length - 1);
		//$output.append(filterValue);
		var $container = $('.filter-container');

		$container.isotope({ filter: filterValue });
	});

	function rangeColour(colour) {
		$.ajax({
			method: "GET",
			url: "/residor/ajax/colourrange",
			data: {
				colour: colour
			}
		}).done(function(msg) {

			var $new = $(msg);

			var newItems = [];

			$('.item', $new).each(function() {
				newItems.push( $(this) );
			});

			$container.isotope('remove', $container.isotope('getItemElements'));

			setTimeout(function() {
				$container.append($new).isotope('appended', $new);

				$container.imagesLoaded().progress( function() {
					$container.isotope('layout');
					//console.log('images loading');
				});

				var $rangeTooltip = $('.range-tooltip');
				var rangeTooltipWidth = $rangeTooltip.outerWidth() + 32;
				var rangeOffset = $rangeTooltip.outerHeight() / 2.5;

				$rangeTooltip.tooltipster({
					animation: 'fade',
					contentAsHTML: true,
					//autoClose: false,
					delay: 500,
					hideOnClick: true,
					touchDevices: true,
					maxWidth: rangeTooltipWidth,
					offsetY: '-' + rangeOffset
				});

			}, 250);

			//
			// /*var $allItems = $container.data('isotope').$allItems;
    		// $container.isotope( 'remove', $allItems );*/
			//
			// $container.isotope('remove', $container.isotope('getItemElements'));
			//
			// /*$('.item', $container).each(function() {
			// 	$(this).remove();
			// });*/
			//
			//
			// setTimeout(function() {
			//
			// 	//$container.isotope('destroy');
			// 	$container.append(newItems); //.isotope('appended', newItems);
			//
			// 	$container.isotope('destroy');
			//
			// 	//$container.isotope('insert', newItems);
			// 	//$container.isotope('layout');
			//
			// 	//$container.isotope('remove', $container.isotope('getItemElements'));
			// 	//$container.isotope('insert', newItems)
			// 	//$container.append( newItems ).isotope('appended', newItems);
			// 	//$container.isotope('layout');
			//
			// 	//$('#door-replace').html(msg);
			//
			// 	// MM - Following code needs work as it is a little messy
			// 	// MM - Appears as if code is executing before #door-replace is fully loaded (setTimeout() is messy).
			//
			// 	var checkedRange = $('.filter-set-1').find('input:checked').data('filter');
			// 	console.log(checkedRange);
			//
			// 	$container.isotope({
			// 		itemSelector: '.slab',
			// 		filter: '.' + checkedRange,
			// 		percentPosition: true,
			// 		masonry: {
			// 			columnWidth: '.grid-sizer'
			// 		}
			// 	});
			//
			// 	$container.isotope('appended', newItems).isotope('layout');
			//
			// 	var $rangeTooltip = $('.range-tooltip');
			// 	var rangeTooltipWidth = $rangeTooltip.outerWidth() + 32;
			// 	var rangeOffset = $rangeTooltip.outerHeight() / 2.5;
			//
			// 	$rangeTooltip.tooltipster({
			// 		animation: 'fade',
			// 		contentAsHTML: true,
			// 		//autoClose: false,
			// 		delay: 500,
			// 		hideOnClick: true,
			// 		touchDevices: true,
			// 		maxWidth: rangeTooltipWidth,
			// 		offsetY: '-' + rangeOffset
			// 	});
			//
			// }, 500);

			// MM - End of code needs work
		});
	}

	$('.door-body #filter .btn:reset').click(function() {
		var colour = 'all';
		rangeColour(colour);
	});

	$('.filter-set-2 select, .filter-set-2 input').change( function() {
		$(".filter-set-2").find("input:checked, option:selected").each(function() {
			var colour = $(this).attr('id');
			rangeColour(colour);

			$('.tooltip').tooltipster('hide'); // , callback
		});
	});

	var triggerImage = $('.filters').find('[data-toggle="trigger"] img');
	var triggerImageUrl = triggerImage.attr('src');
	var triggerImageUrlNew;

	//console.log(triggerImageUrl);

	function colorSelected() {

		if ( $('.filters [data-toggle="container"] input[type="radio"]').is(':checked') ) {
			//console.log('checked');

			var bg = $('.filters [data-toggle="container"] input[type="radio"]:checked').closest('label').css('background-image');
			//bg = bg.replace('url("','').replace('")','');

			bg = /^url\((['"]?)(.*)\1\)$/.exec(bg);
		    bg = bg ? bg[2] : ""; // If matched, retrieve url, otherwise ""

			console.log(bg);

			triggerImageUrlNew = triggerImage.attr('src', bg);

		} else {
			//console.log('not checked');

			triggerImageUrlNew = triggerImage.attr('src', triggerImageUrl);

		}

	}

	$('.filters button[type="reset"]').on('click', function() {
		//console.log('reset');

		$('.no-matches').fadeOut(100);

		$(this).closest('form').find('input').each(function() {
			$(this).attr('checked', false);
		});

		//filterValue = $('.filters input[type="radio"]:checked').data('filter');
		//$output.text('.' + filterValue);

		//$container.isotope({ filter: '.' + filterValue });
		$container.isotope({ filter: '.item' })

		$(this).closest('[data-toggle="container"]').toggleClass('open');

		colorSelected();

	});

	$container.on( 'arrangeComplete',
		function( event, filteredItems ) {

			if ( filteredItems.length > 0 ) {

			} else {
				$('.no-matches').fadeIn(200);
			}

		}
	);

	function activeRadio() {
		$('.filters input[type="radio"]:not(:checked)').parent().removeClass('active');
		$('.filters input[type="radio"]:checked').parent().addClass('active');
	}

	activeRadio();

	$('.filters').on('change', 'input[type="radio"]', function() {

		activeRadio();

		$(this).closest('[data-toggle="container"]').toggleClass('open');

		colorSelected();

	});

	/* Tooltipster */

	var $accessoriesTooltip = $('.accessories-tooltip');
	var accessoriesWidth = $accessoriesTooltip.outerWidth() / 1.5;
	var accessoriesOffset = $accessoriesTooltip.outerHeight() / 6;

	$accessoriesTooltip.tooltipster({
		animation: 'grow',
		contentAsHTML: true,
		//autoClose: false,
		delay: 500,
		hideOnClick: true,
		touchDevices: true,
		maxWidth: accessoriesWidth,
		offsetY: '-' + accessoriesOffset
	});

	var $rangeTooltip = $('.range-tooltip');
	var rangeTooltipWidth = $rangeTooltip.outerWidth() + 32;
	var rangeOffset = $rangeTooltip.outerHeight() / 2.5;

	$rangeTooltip.tooltipster({
		animation: 'fade',
		contentAsHTML: true,
		interactive: true,
		//autoClose: false,
		delay: 500,
		hideOnClick: true,
		touchDevices: false,
		maxWidth: rangeTooltipWidth,
		offsetY: '-' + rangeOffset
	});

	var $tooltipLeft = $('.tooltip-left');

	$tooltipLeft.tooltipster({
		animation: 'fade',
		position: 'left',
		//contentAsHTML: true,
		delay: 500,
		//autoClose: false,
		hideOnClick: true,
		touchDevices: false
	});

	var $tooltip = $('.tooltip');

	$tooltip.tooltipster({
		animation: 'fade',
		//contentAsHTML: true,
		delay: 500,
		//autoClose: false,
		hideOnClick: true,
		touchDevices: false,
		theme: 'tooltipster-default z5'
	});

	/* Sticky */

	var vw = $(window).width();
	var headerHeight = $('header').outerHeight();

	if ( $('.sticky-element').length > 0 )  {

		function stickyWrapperHeight() {

			var stickyWrapper = $('.sticky-wrapper');
			var stickyElement = $('.sticky-element');
			var stickyHeight = stickyElement.outerHeight();

			stickyWrapper.each(function() {
				$(this).height(stickyHeight);
			});

		}

		// grab the initial top offset of the navigation
		var stickyOffsetTop = $('.sticky-element').offset().top - headerHeight;

		// our function that decides weather the navigation bar should have "fixed" css position or not.
		var stickyFunc = function(){
			var vwResize = $(window).width();
			var scrollTop = $(window).scrollTop(); // our current vertical position from the top

			//console.log(scrollTop + ', ' + stickyOffsetTop);
			// if we've scrolled more than the navigation, change its position to fixed to stick to top,
			// otherwise change it back to relative
			if (scrollTop > stickyOffsetTop && vwResize >= 960) {
				$('.sticky-element').css({ 'position': 'fixed', 'top': headerHeight, 'left': 0, 'z-index': 10 });
			} else {
				$('.sticky-element').css({ 'position': 'relative', 'top': 0 });
			}
		};

		// run our function on load
		stickyWrapperHeight();
		stickyFunc();

		// and run it again every time you scroll
		$(window).scroll(function() {
			 stickyFunc();
		});

		$(window).resize(function() {
			stickyWrapperHeight();
			 stickyFunc();
		});

	};

	$('#loadmore').click(function(e) {
		e.preventDefault();
		var offset = $('.item').length;
		$.ajax({
			method: "GET",
			url: "/residor/ajax/loadmore",
			data: {
				offset : offset,
			}
		}).done(function(msg) {
			if(msg) {
				var $new = $(msg);
				var $container = $('.filter-container');
				setTimeout(function() {
					$container.append($new).isotope('appended', $new);
				}, 250);
			} else {
				$('a#loadmore').html("No more images to load");
			}
		});
	});
	
	if($('#cookie-law-info-bar').is(":visible")	){
		$('body').addClass('cookie-bar-show');
		$('.cli_action_button, .wt-cli-privacy-accept-btn ').on('click', function() {
			$('body').removeClass('cookie-bar-show'); 
		});
	}
});
